import React, { useState, useEffect } from "react"
import DefaultLanguageInterface, { QAProps } from "../../../interfaces";
import greekLanguage from '../../../locales/gr.json';
import englishLanguage from '../../../locales/en.json';
import { IonIcon } from "@ionic/react";
import './DriverCommonQuestions.css';
import DefaultDriverAccordeon from "./DefaultDriverAccordeon/DefaultDriverAccordeon";
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "../../../@/components/ui/accordion";
import DownloadOurApp from "../../Home/DownloadOurApp/DownloadOurApp";
import HomeDriver from "../../Home/HomeDriver/HomeDriver";

const DriverCommonQuestions: React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [filteredQuestions, setFilteredQuestions] = useState<QAProps[]>([]);

    let languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;

    const getAnswerByQuestion = (question: [string, string]) => {
        const [questionKey, _] = question;
        const answerKey = questionKey + 'a';
        const driver_faq = languageData.driver_faq as { [key: string]: string };

        if (driver_faq.hasOwnProperty(answerKey)) {
            return driver_faq[answerKey];
        } else {
            return `Answer for question "${questionKey}" not found`;
        }
    };

    const getQestionbyAnswer = (answer: [string, string]) => {
        const [answerKey, _] = answer;
        const questionKey = answerKey.replace('a', '');
        const driver_faq = languageData.driver_faq as { [key: string]: string };

        if (driver_faq.hasOwnProperty(questionKey)) {
            return driver_faq[questionKey];
        } else {
            return `Question for answer "${answerKey}" not found`;
        }
    }

    const isAnswer = (entry: [string, string]) => {
        return entry[0].includes('a');
    }

    const filterQuestions = (term: string) => {
        const faqEntries = Object.entries(languageData.driver_faq);
        const filteredEntries = faqEntries.filter(entry => {
            if (entry[0].includes('q') && entry[0].includes('_')) {
                return entry[1].toLowerCase().includes(term.toLowerCase());
            }
            return false;
        });
        const temp_array = [] as QAProps[];
        setFilteredQuestions([])
        filteredEntries.forEach(entry => {
            if (isAnswer(entry)) {
                const question = getQestionbyAnswer(entry);
                if (!temp_array.some(qa => qa.question === question)) {
                    temp_array.push({ question: question, answer: entry[1] });
                    setFilteredQuestions(prevState => [...prevState, { question: question, answer: entry[1] }]);
                }
            } else {
                const answer = getAnswerByQuestion(entry);
                if (!temp_array.some(qa => qa.answer === answer)) {
                    temp_array.push({ question: entry[1], answer: answer });
                    setFilteredQuestions(prevState => [...prevState, { question: entry[1], answer: answer }]);
                }
            }
        });

    }


    useEffect(() => {
        filterQuestions(searchTerm);
    }, [searchTerm]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div id="driver_faq-container">
                <div className='w-full flex items-center py-10 driver-header h-[360px] mb-10'>
                    <div className='max-w-screen-1128 w-full m-auto flex items-center justify-center flex-col gap-10'>
                        <div className="text-5xl text-white">
                            {languageData.driver_faq.title}
                        </div>
                        <div className='w-4/5 max-w-screen-1128 relative z-0'>
                            <IonIcon icon="search-outline" className={`absolute text-xl top-3 left-3 ${isInputFocused ? 'text-main' : 'text-neutral-500'} border-r border-neutral-500 pr-1.5`} />
                            <input type="text" onFocus={() => setIsInputFocused(true)} onChange={(event) => setSearchTerm(event.target.value)} onBlur={() => setIsInputFocused(false)} placeholder={languageData.driver_faq.search_placeholder} className='py-2 pl-12 rounded-full border-2  shadow-sm border-neutral-400 focus:outline-main focus:shadow-lg w-full duration-75' />
                        </div>
                    </div>

                </div>

                {searchTerm === "" ?
                    <div className="w-full flex align-start justify-start h-full max-w-screen-1128 m-auto pt-10 pb-20">
                        <DefaultDriverAccordeon activeLanguage={activeLanguage} />

                    </div>
                    :

                    <>
                        {filteredQuestions.length > 0 ? (
                            <Accordion type="single" collapsible className="w-full max-w-1128 m-auto pt-10 pb-20">
                                {filteredQuestions.map((qa, index) => (
                                    <AccordionItem key={index} value={`mobile-app__inner-${index + 1}`}>
                                        <AccordionTrigger className="passenger_faq_sub_title">{qa.question}</AccordionTrigger>
                                        <AccordionContent > <div dangerouslySetInnerHTML={{ __html: qa.answer.replace('</br>', '') }}></div></AccordionContent>
                                    </AccordionItem>
                                ))}
                            </Accordion>
                        ) : (
                            <p className='max-w-1128 m-auto pt-40 pb-40 text-center'>{languageData.driver_faq.no_results_text} "{searchTerm}"</p>
                        )}
                    </>
                }
                <HomeDriver activeLanguage={activeLanguage} />
                <DownloadOurApp activeLanguage={activeLanguage} showTitle={true} />
            </div>
        </>
    )

}

export default DriverCommonQuestions;