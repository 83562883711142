import React from 'react';
import locationIcon from '../../../images/buttons/location_outline.svg';
import './ContactLocationButton.css';

// Define the props interface for the component
interface ContactLocationButtonProps {
  url: string;
}

const ContactLocationButton: React.FC<ContactLocationButtonProps> = ({ url }) => {
  return (
    <a href={url} className='loc_button'>
      <img src={locationIcon} alt="" /> 
      <div className='loc_button_text'> Εμφάνιση τοποθεσίας </div> 
    </a>
  );
};

export default ContactLocationButton;