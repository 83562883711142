import { ACTIVE_URL } from "../../../../constants";
import { NewsItem } from "../../../../interfaces";

// Fetch news data from the API
export const fetchHomeNews = async (): Promise<NewsItem[]> => {
    const response = await fetch(`${ACTIVE_URL}/api/get_recent_news/`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });
    if (!response.ok) {
        throw new Error('Failed to fetch news');
    }
    const data = await response.json();

    return data.map((item: any) => ({
        grTitle: item.gr_title,
        enTitle: item.en_title,
        grDescription: item.gr_description,
        enDescription: item.en_description,
        image: item.image_data,
        date: item.date,
        slug: item.slug
    }));
};