import React, { useState } from 'react';
import { IonIcon } from '@ionic/react';
import { useAuthContext } from '../../../../../utils/useAuthContext';
import { ACTIVE_URL } from '../../../../../constants';
import AdminSettingsStatusActiveFooter from '../AdminSettingsStatusActiveFooter/AdminSettingsStatusActiveFooter';
import AdminActions from '../../../AdminActions/AdminActions';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../../../../@/components/ui/select";

type ColorType = {
    id: number;
    name: string;
    hex_code: string;
    active: boolean;
}

interface Stage {
    id: number;
    title: string;
    priority: number;
    category: number;
    timelimit: number;
}

interface AdminAddSettingsStatusProps {
    category_id: number;
    status_colors: ColorType[];
    onAddStage: (stage: Stage) => void;
}

type TagType = {
    id: string;
    name: string;
}

interface emailDataProps {
    type?: 'email';
    from: string;
    title: string;
    email: string;
    visibleTo: string | string[];
}

interface noteDataProps {
    type?: 'note';
    note: string,
    ccGroup: string,
}

interface tagsDataProps {
    type?: 'tags';
    activeTags: TagType[],
    visibilityGroup: string,
}

type ActionTypes = emailDataProps | noteDataProps | tagsDataProps;

interface addStatusDataProps {
    name: string | null;
    color: string | null;
    timelimit: string | undefined;
    actions: ActionTypes[];
}

const AdminAddSettingsStatus: React.FC<AdminAddSettingsStatusProps> = ({ category_id, status_colors, onAddStage }) => {
    const { authTokens } = useAuthContext();

    const initialAddStatusData = {
        name: null,
        color: null,
        timelimit: undefined,
        actions: [],
    }

    const [view, setView] = useState<string>('closed');

    const [addStatusData, setAddStatusData] = useState<addStatusDataProps>(initialAddStatusData);

    const [selectedAddAction, setSelectedAddAction] = useState<string | undefined>(undefined);
    const [isSavable, setIsSavable] = useState<boolean>(false);

    const toggleView = () => {
        setView(view === 'closed' ? 'open' : 'closed');
    }

    const handleSelectColorClick = (id: string) => {
        setAddStatusData({ ...addStatusData, color: id })
    }

    const handleStatusNameChange = (name: string) => {
        setAddStatusData({ ...addStatusData, name: name })
        if (addStatusData.name !== '') {
            setIsSavable(true);
        } else {
            setIsSavable(false);
        }
    }

    const resetSelectedAction = () => {
        setSelectedAddAction(undefined);
    }

    const addAction = (action: ActionTypes) => {

        setAddStatusData({ ...addStatusData, actions: [...addStatusData.actions, action] })
        console.log(action)
        resetSelectedAction();
    }

    const handleTimelimitChange = (timelimit: string | undefined) => {
        setAddStatusData({ ...addStatusData, timelimit: timelimit })
    }

    const handleCancel = () => {
        setView('closed');
        setAddStatusData(initialAddStatusData);
    }

    const handleCloseAction = (index: number) => {
        const actions = addStatusData.actions;
        const newActions = actions.filter((action, idx) => idx !== index);
        setAddStatusData({ ...addStatusData, actions: newActions });
    }

    const handleSaveStatus = () => {

        const postStatus = async () => {
            if (!authTokens || !authTokens.access) {
                return;
            }
            try {
                const response = await fetch(`${ACTIVE_URL}/api/save_new_status/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authTokens.access}`,

                    },
                    body: JSON.stringify({ category_id: category_id, name: addStatusData.name, color: addStatusData.color, timelimit: addStatusData.timelimit, actions: addStatusData.actions })
                });
                if (response.ok) {
                    const data = await response.json();
                    handleCancel();
                    onAddStage({
                        id: data.new_stage_id,
                        title: data.title,
                        priority: data.priority,
                        category: data.category,
                        timelimit: parseInt(data.timelimit)
                    });
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        };
        postStatus();

    }

    const handleStatusActionsChange = (actions: ActionTypes) => {
        console.log(actions)
    }

    return (
        <div>
            {view === 'closed' &&
                <div className='border border-gray-400 border-dashed rounded-sm p-2 text-sm text-gray-500  flex items-center justify-center cursor-pointer' onClick={toggleView}>
                    <IonIcon icon="add-outline" className='text-lg mr-2' /> Add New
                </div>
            }
            {view === 'open' &&
                <div className='border select-none p-4 rounded-md border-gray-300' style={{ background: 'rgb(232,245,252)' }}>
                    <div className='flex gap-4'>
                        <div className='flex flex-col gap-1'>
                            <label htmlFor="stage_name"><IonIcon icon="clipboard" className='ion-icon-small-transform mr-0.5' /> Stage Name</label>
                            <input id="stage_name" type="text" className='h-10 text-sm focus:outline-none border rounded-md px-2' onChange={(event) => handleStatusNameChange(event.target.value)} />
                        </div>
                        <div className='flex flex-col gap-1'>
                            <label htmlFor="stage_color"><IonIcon icon="color-palette" className='mr-0.5 ion-icon-small-transform' /> Color</label>
                            <Select onValueChange={(option) => handleSelectColorClick(option)} >
                                <SelectTrigger className="w-[180px] focus:outline-none">
                                    <SelectValue placeholder='Select Color' />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        {status_colors.map((color: ColorType) => (
                                            <SelectItem key={`color-${color.hex_code}`} value={color.id.toString()} className='flex items-center outline-none' >
                                                <IonIcon icon='ellipse' className='ion-icon-default-transform mr-1 ml--1' style={{ color: color.hex_code }} />{color.name}
                                            </SelectItem>
                                        ))}
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </div>
                        <div className='flex flex-col gap-1'>
                            <label htmlFor="time_limit" ><IonIcon icon="alarm" className='mr-0.5 ion-icon-small-transform' />Time Limit</label>
                            <Select onValueChange={(option) => handleTimelimitChange(option)} value={addStatusData.timelimit} >
                                <SelectTrigger className="w-[180px] focus:outline-none">
                                    <SelectValue placeholder='Select Action' />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        <SelectItem key="select-timelimit-0" value="0" className='flex items-center outline-none' >
                                            No Time Limit
                                        </SelectItem>
                                        <SelectItem key="select-timelimit-1" value="1" className='flex items-center outline-none' >
                                            1 day
                                        </SelectItem>
                                        <SelectItem key="select-timelimit-2" value="2" className='flex items-center outline-none' >
                                            2 days
                                        </SelectItem>
                                        <SelectItem key="select-timelimit-3" value="3" className='flex items-center outline-none' >
                                            3 days
                                        </SelectItem>
                                        <SelectItem key="select-timelimit-5" value="5" className='flex items-center outline-none' >
                                            5 days
                                        </SelectItem>
                                        <SelectItem key="select-timelimit-7" value="7" className='flex items-center outline-none' >
                                            7 days
                                        </SelectItem>
                                        <SelectItem key="select-timelimit-14" value="14" className='flex items-center outline-none' >
                                            14 days
                                        </SelectItem>
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </div>
                    </div>
                    <div>
                        {addStatusData.actions.map((action: ActionTypes, index: number) => (
                            <div key={`status-action-${index}`}>
                                <AdminActions initialAction={action} setStatusActionData={(handleStatusActionsChange)} closeAction={() => handleCloseAction(index)} className='my-4' />
                            </div>
                        ))}
                    </div>
                    <AdminSettingsStatusActiveFooter key={category_id + 'add_status_footer'} cancelStatusAdd={handleCancel} addNewAction={addAction} saveAvailability={isSavable} saveStatus={handleSaveStatus} />
                </div>
            }
        </div>
    )
}

export default AdminAddSettingsStatus;