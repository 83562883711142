import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../../../utils/useAuthContext';
import AdminBox from '../Helpers/AdminBox/AdminBox';
import AdimBoxTitle from '../Helpers/AdminBoxTitle/AdimBoxTitle';
import { ACTIVE_URL } from '../../../constants';

type AdminFormAnswerSingleProps = {
    id: string;
};

const AdminFormAnswerSingle: React.FC<AdminFormAnswerSingleProps> = ({ id }) => {
    const { authTokens } = useAuthContext();
    const [curId, setCurId] = useState<string>(id);


    useEffect(() => {
        const fetchResponse = async () => {
            if (!authTokens || !authTokens.access) {
                return;
            }
            try {
                const response = await fetch(`${ACTIVE_URL}/api/get_response/${id}/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authTokens.access}`,
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    console.log(data)
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        };
        fetchResponse();
    }, [id]);


    return (
        <AdminBox>
            <AdimBoxTitle title={`Response #${curId}`} />
            {id}
        </AdminBox>
    )

}

export default AdminFormAnswerSingle;