import React from 'react';

interface DriverFormProgressBarProps {
    currentStep: number;
    totalSteps: number;
    className?: string;
}

const DriverFormProgressBar: React.FC<DriverFormProgressBarProps> = ({ currentStep, totalSteps, className = '' }) => {
    // Calculate the width of the progress bar as a percentage
    const progress = (currentStep / totalSteps) * 100;

    return (
        <>
            <div className='w-full absolute top-4 px-4 h-3 flex items-center justify-center gap-2'>
                <div className=' text-white'>
                    {currentStep}/{totalSteps}
                </div>
                <div className={`${className} w-full bg-white rounded-full overflow-hidden `}>

                    <div
                        style={{ width: `${progress}%` }}
                        className="bg-main h-3 rounded-full transition-width duration-300 ease-in-out"
                    ></div>
                </div>
            </div>

        </>

    );
}

export default DriverFormProgressBar;


