import React, { useEffect } from 'react';
import DriverHeader from '../../Components/Driver/DriverHeader/DriverHeader';
import DefaultLanguageInterface from '../../interfaces';
import DriverReasons from '../../Components/Driver/DriverReasons/DriverReasons';
import DownloadOurApp from '../../Components/Home/DownloadOurApp/DownloadOurApp';
import DriverHowItWorks from '../../Components/Driver/DriverHowItWorks/DriverHowItWorks';


const Contact: React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <DriverHeader activeLanguage={activeLanguage} />
      <DriverReasons activeLanguage={activeLanguage} />
      <DriverHowItWorks activeLanguage={activeLanguage} />
      {/* <HomeDriver activeLanguage={activeLanguage} buttonText='Κάνε εγγραφή' buttonClickLink={`${activeLanguage == 'en' ? '/en' : ''}/drivers-form`}/> */}
      <DownloadOurApp activeLanguage={activeLanguage} selectedMode={'driver'} />
    </div>
  )
};

export default Contact;