import React, { useState } from 'react';
import { useAuthContext } from '../../../../../utils/useAuthContext';
import CandidateApprovalRating from './CandidateApprovalRating/CandidateApprovalRating';

interface Note { 
    id: number;
    note: string;
    days_since_date_created: number;
    info_author: string;
}

interface candidateInfo {
    id: number;
    name: string;
    email: string;
    phone: string;
    score: number;
    stage: string;
    date_created: string;
    notes: Note[];
}

interface AdminViewCandidateRightPanelProps {
    thisCandidateInfo: candidateInfo;
}


const AdminViewCandidateRightPanel:React.FC<AdminViewCandidateRightPanelProps> = ({thisCandidateInfo}) => {
    const { authTokens } = useAuthContext();
    const [candidateInfo, setCandidateInfo] = useState<candidateInfo>(thisCandidateInfo);

    console.log(candidateInfo);

    return (
        <div className='border-l-2 border-gray-400 px-3 py-2 bg-panelBgGray overflow-y-auto'>
            <div className='flex items-center justify-between'>
                <div>
                    <CandidateApprovalRating score={candidateInfo.score} />
                </div>
                <div>

                </div>
            </div>
            <div>

            </div>
            <div>
                
            </div>
        </div>
    )
}

export default AdminViewCandidateRightPanel;