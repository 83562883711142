import React from 'react';
import './ResponseRowHeader.css';

const ResponseRowHeader: React.FC = () => {

    return (
        <div className='response-header'>
            <div>Name</div>
            <div>Email</div>
            <div>Last Updated</div>
            <div>Status</div>
            <div>Download</div>
        </div>
    );
};

export default ResponseRowHeader;