import React, { useState } from "react";
import QrPopup from './../HomeHero/QrPopup/QrPopup';
import ActionButton from '../../ActionButton/ActionButton';
import greekLanguage from '../../../locales/gr.json';
import englishLanguage from '../../../locales/en.json';
import HTMLRenderer from "../../../utils/HTMLRenderer";
import StackedImages from './StackedImages/StackedImages';
import passengerImage from '../../../images/downloadTheApp/taxi_at_hand.png';

interface DownloadOurAppProps {
    activeLanguage: string;
    showTitle?: boolean;
    selectedMode?: 'passenger' | 'driver';
}

const DownloadOurApp: React.FC<DownloadOurAppProps> = ({ activeLanguage, selectedMode='passenger', showTitle = true }) => {
    const [qrPopup, setQrPopup] = useState(false);
    const [mode, setMode] = useState<'passenger' | 'driver'>(selectedMode);
    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;
    const toggleQrPopup = () => {
        setQrPopup(!qrPopup);
    }

    return (
        <div className="px-4 xmd:px-0 xmd:pt-12 xmd:pb-16">
            {
                showTitle ? 
                <div className="max-w-1128 mx-auto">
                    <h2 className="text-4xl xmd:text-5xl mb-6 xmd:mb-8 xmd:text-center text-neutral-800 font-semibold">{languageData.download_our_app.title}</h2>
                    <div className="flex items-center gap-3 ml-1 xmd:ml-0 xmd:justify-center xmd:pb-0">
                        <div onClick={() => setMode('passenger')} className={`${mode === 'passenger' ? 'bg-main text-neutral-50' : 'bg-netral-50 text-main'} px-4 py-1 rounded-full border border-main text-sm cursor-pointer`}>
                            {languageData.menu.passenger}
                        </div>
                        <div onClick={() => setMode('driver')} className={`${mode === 'driver' ? 'bg-main text-neutral-50' : 'bg-netral-50 text-main'} px-4 py-1 rounded-full border border-main text-sm  cursor-pointer`}>
                            {languageData.menu.driver}
                        </div>
                    </div>
                </div>
                :
                null
            }

            <div className="my-8 py-2 px-4 relative bg-main max-w-1128 mx-auto rounded-xl flex flex-col md:flex-row overflow-hidden md:gap-x-4 md:min-h-[500px]">
                <div className="flex flex-col justify-center pl-4 md:pl-24 pr-4 md:pr-0 pt-10 md:pt-8 md:pb-8  mb-8 md:mb-0 md:w-1/2">
                    <div className="flex flex-col md:gap-y-8 gap-y-6">
                        <h3 className="font-bold mt-0 text-3xl md:text-[2.6rem] mb-0 text-white">
                            <HTMLRenderer className="leading-snug " htmlContent={mode === 'passenger' ? languageData.download_our_app.header : languageData.download_our_app.header_driver } />
                            {/* <div className="leading-snug text-[2.5rem]">{languageData.download_our_app.header}</div> */}
                        </h3>
                        <p className="mt-0 text-lg md:text-xl leading-[1.625rem] md:leading-7 font-normal mb-0 text-white">{languageData.download_our_app.text}</p>
                        <div>
                            <ActionButton activeLanguage={activeLanguage} className={"inline-block text-base btn btn-primary bg-neutral-50 py-3 px-6 font-semibold rounded-full hover:bg-neutral-100 duration-100"} text_english={'Get the app'} text_greek={'Κατέβασε την εφαρμογή'} onClick={toggleQrPopup} />
                        </div>
                    </div>
                </div>
                <div className="md:absolute md:right-0 md:w-1/2 flex items-center h-full justify-center">
                    {
                        mode === 'passenger' ?
                        <img src={passengerImage} alt="taxi.gr passenger app" className="mb-[-10px] xmd:mb-0" />
                        :
                        <StackedImages mode={mode} />
                    }
                    {/* <StackedImages mode={mode} /> */}
                    
                    {/* <img src={app} alt="" className="h-[90%] max-w-[65vw]" /> */}
                </div>
            </div>
            {qrPopup && <QrPopup onClose={() => setQrPopup(false)} forProp={mode} activeLanguage={activeLanguage} header_text={mode === 'passenger' ? languageData.home.home_popup_header : languageData.home.home_popup_header_driver} body_text={mode === 'passenger' ? languageData.home.home_popup_text : languageData.home.home_popup_text_driver} />}
        </div>
    )
}

export default DownloadOurApp;