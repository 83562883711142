export const isValidEmail = (email: string): boolean => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
};

// Utility function to validate phone number
// This is a very basic validation and might need to be adjusted based on the expected phone number formats
export const isValidPhoneNumber = (number: string): boolean => {
    const pattern = /^\+?\d{10,10}$/;
    return pattern.test(number);
};