import React from 'react'
import { IonIcon } from '@ionic/react'
import profileImg from '../../../../../images/defaults/profile_icon.png';
interface User {
    first_name: string;
    last_name: string;
    email: string;
    username: string;
    group: string;
}

const UserTab: React.FC<User> = ({ first_name, last_name, email, username, group }) => {

    return (
        <div className='mx-4'>
            <div className='px-2 mx-0 my-3 py-2 border border-gray-400 rounded-sm'>
                <li key={username} className='flex items-center cursor-grab select-none w-full'>
                    <div className='h-8 w-8'>
                        <img src={profileImg} alt="Profile Image" className='rounded-full'/>
                    </div>
                    <div className='ml-3 w-52'>
                        {first_name} {last_name}
                    </div>
                    <div className='ml-3 w-28'>
                        {username}
                    </div>
                    <div className='ml-3 w-28'>
                        {group}
                    </div>
                    <div className='ml-3 w-34'>
                        {email}
                    </div>
                    <div className='ml-auto flex px-2 items-center text-gray-600 hover:text-black hover:bg-gray-200 cursor-pointer rounded-sm py-2'>
                        <IonIcon icon='pencil-outline' />
                    </div>
                </li>
            </div>

        </div>
    )

}

export default UserTab;