import React, { useState } from 'react';
import { useAuthContext } from '../../../../../utils/useAuthContext';

interface Note { 
    id: number;
    note: string;
    days_since_date_created: number;
    info_author: string;
}

interface candidateInfo {
    id: number;
    name: string;
    email: string;
    phone: string;
    score: number;
    stage: string;
    date_created: string;
    notes: Note[];
}

interface AdminViewCandidateLeftPanelProps {
    thisCandidateInfo: candidateInfo;
}

const AdminViewCandidateLeftPanel:React.FC<AdminViewCandidateLeftPanelProps> = ({thisCandidateInfo}) => {
    const { authTokens } = useAuthContext();
    const [candidateInfo, setCandidateInfo] = useState<candidateInfo>(thisCandidateInfo);

    return (
        <div className='px-3 py-2 bg-panelBgGray overflow-y-auto'>
        dfd
        </div>
    )

}

export default AdminViewCandidateLeftPanel;