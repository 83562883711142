import React, { useEffect, useState, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { IonIcon } from "@ionic/react";

import DefaultLanguageInterface, { NewsItem } from "../../../interfaces";
import HomeNewsItem from "./HomeNewsItem/HomeNewsItem";
import { fetchHomeNews } from "./HomeNewsAPI/HomeNewsAPI";

import greekLanguage from '../../../locales/gr.json';
import englishLanguage from '../../../locales/en.json';

const HomeNews: React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
    const [hoverTitle, setHoverTitle] = useState<boolean>(false);
    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;

    const { data: newsItems = [] as NewsItem[], isLoading, error } = useQuery<NewsItem[], Error>({
        queryKey: ["recentNews"],
        queryFn: fetchHomeNews,
        staleTime: 5 * 60 * 1000
    });

    if (error) {
        console.error('Error fetching news:', error);
    }

    return (
        <div className="pt-14 pb-14 px-3 xmd:px-0 max-w-1128 m-auto">
            <div className="flex justify-between w-full items-center select-none">
                <h1 className='sm:text-center text-3xl sm:text-4xl xmd:5xl font-semibold mt-10 mb-8 sm:mb-12 text-neutral-800'>{languageData.home.home_news}</h1>
                <div>
                    <a href="/news" className="duration-100 hidden sm:flex text-neutral-700 hover:text-main transition  text-lg xmd:text-lg font-semibold items-center gap-2" onMouseOver={() => setHoverTitle(true)} onMouseLeave={() => setHoverTitle(false)}>
                        <div className="duration-100">{languageData.home.home_news_view_all}</div>
                        <IonIcon icon='arrow-forward-outline' className={`text-xl duration-200 inline-block ${hoverTitle ? 'translate-x-0.5' : ''}`} />
                    </a>
                </div>
            </div>
            <div className="flex flex-col sm:grid  sm:grid-cols-2 xmd:grid-cols-3  gap-4 sm:gap-2 xmd:gap-6">
                {newsItems.map((item, index) => (
                    <div key={index}>
                        <HomeNewsItem loading={isLoading} grTitle={item.grTitle} enTitle={item.enTitle} grDescription={item.grDescription} enDescription={item.enDescription} image={item.image} slug={item.slug} date={item.date} activeLanguage={activeLanguage} />
                    </div>
                ))}
            </div>
            <div>
                <a href="/news" className="duration-100 flex sm:hidden mt-8 text-neutral-700 hover:text-main transition  text-lg xmd:text-lg font-semibold items-center gap-2" onMouseOver={() => setHoverTitle(true)} onMouseLeave={() => setHoverTitle(false)}>
                    <div className="duration-100">{languageData.home.home_news_view_all}</div>
                    <IonIcon icon='arrow-forward-outline' className={`text-xl duration-200 inline-block ${hoverTitle ? 'translate-x-0.5' : ''}`} />
                </a>
            </div>
        </div>
    );
}

export default HomeNews;