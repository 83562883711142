import React, { useEffect, useRef, useState } from 'react';
import { IonIcon } from '@ionic/react';
import { RemoveScroll } from 'react-remove-scroll';
import './QrPopup.css';
import HTMLRenderer from '../../../../utils/HTMLRenderer';
import StackedImages from '../../DownloadOurApp/StackedImages/StackedImages';
import { AppRedirectLink } from '../../../../utils/AppRedirectLink/AppRedirectLink';

interface QrPopupProps {
    header_text: string;
    body_text: string;
    onClose: () => void;
    forProp?: 'passenger' | 'driver';
    activeLanguage: string;
}

const QrPopup: React.FC<QrPopupProps> = ({ onClose, header_text, body_text, forProp = 'passenger', activeLanguage }) => {
    const [isMobile, setIsMobile] = useState(true);
    const redirected = useRef(false);

    const handleClose = () => {
        onClose();
    };

    const handlePopupClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };

    useEffect(() => {
        if (redirected.current) {
            return;
        }
        const userAgent = navigator.userAgent;
        const isMobile = /android|avantgo|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(userAgent);

        if (isMobile ) {
            const newTab = window.open(AppRedirectLink(activeLanguage, forProp, 'website'), '_blank');
            if (newTab) {
                setTimeout(() => newTab.close(), 5000);
            } else {
                console.error('Failed to open a new tab.');
            }
        }
        if (!isMobile) {
            setIsMobile(false);
        }
        redirected.current = true;
    }, []);

    if (isMobile) {
        return null;
    }

    return (
        <RemoveScroll>
            <div className="fixed top-0 left-0 w-full h-full animate-fadeIn  bg-black bg-opacity-40 flex items-center justify-center z-10" onClick={handleClose}>
                <div className="bg-main select-none py-24 px-24 rounded-xl relative max-w-80 overflow-y-hidden flex flex-row overflow-x-auto max-w-1128 w-full" onClick={handlePopupClick}>
                    <span className="absolute top-4 right-4 text-xl text-white flex  items-center py-3 px-3 bg-main hover:bg-blue-600 duration-100 rounded-full cursor-pointer z-10" onClick={handleClose}>
                        <IonIcon icon="close" />
                    </span>
                    <div className='w-1/2 text-left'>
                        <div className="text-white text-5xl font-bold mb-6 leading-snug">
                            <HTMLRenderer htmlContent={header_text} />
                        </div>
                        <div className="text-white text-lg">{body_text}</div>
                    </div>
                    <div className='md:absolute md:right-[2%] w-full flex items-center h-full justify-center'>
                        <div className='w-[300px] h-[150%] relative'>
                            <StackedImages mode={forProp} className='absolute left-3/4' />
                        </div>
                    </div>
                    <div className={`h-[10.5rem] w-[10.5rem] absolute top-1/2 right-[31%] rounded-sm shadow-lg ${forProp == 'passenger' ? 'qr_wrap_passenger' : 'qr_wrap_driver'}`}></div>
                </div>
            </div>
        </RemoveScroll>
    );
};


export default QrPopup;
