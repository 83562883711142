import React, { useState } from 'react';
import './HomeHero.css';
import startPin from '../../../images/Taxi_icons/svgs/start_pin.svg';
import ActionButton from '../../ActionButton/ActionButton';
import QrPopup from './QrPopup/QrPopup';
import greekLanguage from '../../../locales/gr.json';
import englishLanguage from '../../../locales/en.json';
import { IonIcon } from '@ionic/react';

interface HomeHeroProps {
    activeLanguage: string;
}

const HomeHero: React.FC<HomeHeroProps> = (props) => {
    const [qrPopup, setQrPopup] = useState(false);
    const { activeLanguage } = props;
    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;

    const toggleQrPopup = () => {
        setQrPopup(!qrPopup);
    }
    // Don't autofocus on mobile - fix for redirects
    const userAgent = navigator.userAgent;
    const isMobile = /android|avantgo|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(userAgent);
    return (
        <div className="home_header-container h-[95vh] sm:h-[90vh] flex  px-4 1240:pl-0 select-none">
            <div className="max-w-1128 animate-fadeUp w-full h-full flex flex-col justify-end text-white m-auto xmd:justify-center">
                <div className='text-6xl mb-6 max-w-[425px] mt-16'>
                    <span className={`${activeLanguage == 'el' ? 'text-[8rem] inline-block' : 'text-[8rem] inline-block' } `}>{languageData.home.home_header_title_1}</span>
                    <span className={`${activeLanguage == 'el' ? 'text-[4.15rem] inline-block' : 'text-[3.3rem] inline-block' } `}>{languageData.home.home_header_title_2}</span>
                </div>
                <p className='mb-4 text-base hidden xmd:block '>{languageData.home.home_header_text_main}</p>
                <div className='flex flex-col gap-2.5 relative'>
                    <div className='absolute top-2.5 left-3 text-black z-10'>
                        <div className='flex flex-col gap-7 '>
                            <img src={startPin} alt="" className='h-[20px] animate-fadeUp absolute top-1' />
                            <div className='bg-main h-7 w-0.5 absolute top-[26.2px] left-[8.7px] animate-fadeUp '>
                            </div>
                            <img src={startPin} alt="" className='text-main text-xl animate-fadeUp mt-[56px] h-[20px]' />
                        </div>
                    </div>
                    <div className='h-11 bg-white flex justify-between  items-bottom  rounded-md font-semibold animate-fadeUp xmd:pb-6 pb-5 leading-tight xmd:max-w-[420px]'>
                        <div className='w-[90%] text-[0.95rem] select-none flex items-center justify-start font-normal pl-10 h-11  text-md px-2 text-black rounded-md outline-none'>
                            <div>
                                {languageData.home.home_header_location_current}
                            </div>
                        </div>
                        <div className='w-[10%] h-10 flex items-center justify-center '>
                            <IonIcon icon='navigate' className='text-xl text-neutral-800' />
                        </div>
                    </div>
                    <div className='h-11 bg-white flex justify-between  items-bottom  rounded-md font-semibold animate-fadeUp xmd:pb-6 pb-5 leading-tight xmd:max-w-[420px]'>
                        <input type="text" autoFocus={!isMobile} placeholder={languageData.home.home_header_location_destination} className='caret-main text-[0.95rem] stroke-2 w-[90%] font-normal pl-10 h-11 px-2 text-black rounded-md outline-none' />
                        <div className='w-[10%] h-10 flex items-center justify-center'>

                        </div>
                    </div>
                    <div className='button_div delay-150 animate-fadeUp scale-0.75 mb-[6vh] mt-2'>
                        {/* <BrowserView> */}
                        <ActionButton className='btn btn-primary bg-main py-[0.565rem] px-6 rounded-md hover:bg-secondary duration-10' activeLanguage={activeLanguage} text_english={'Book your ride'} text_greek={'Κάλεσε ταξί'} onClick={toggleQrPopup} />
                        {/* </BrowserView> */}

                    </div>
                </div>

            </div>
            {qrPopup && <QrPopup onClose={() => setQrPopup(false)} activeLanguage={activeLanguage} header_text={languageData.home.home_popup_header} body_text={languageData.home.home_popup_text} />}
        </div>
    );
};
export default HomeHero;
