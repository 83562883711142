interface SwitchProps {
    checked: boolean;
    onChange: (ch: boolean) => void;
    activeColorClass?: string;
    inactiveColorClass?: string;
    wrapperClass?: string;
    innerClass?: string;
}

const Switch: React.FC<SwitchProps> = ({ checked, onChange, innerClass, activeColorClass, inactiveColorClass, wrapperClass }) => {
    return (
        <div className={wrapperClass}>
            <label className="relative inline-flex items-center cursor-pointer">
                <input
                    type="checkbox"
                    value=""
                    checked={checked}
                    className="sr-only peer"
                    onChange={() => onChange(!checked)} // Pass the onChange function directly here
                />
                <div className={`w-9 h-5 ${checked ? activeColorClass : inactiveColorClass}  peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full 
                                peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300
                                after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:${checked ? activeColorClass : inactiveColorClass} ${innerClass}`}>
                </div>
            </label>
        </div>
    );
};

export default Switch;