import React, { useEffect, useState } from 'react';
import { ACTIVE_URL } from '../../../../constants';
import DefaultLanguageInterface from '../../../../interfaces';
import HTMLRenderer from '../../../../utils/HTMLRenderer';

const TermsAndConditionsContent:React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
    const [TermsAndConditionsContentGr, setTermsAndConditionsContentGr] = useState<string>('');
    const [TermsAndConditionsContentEn, setTermsAndConditionsContentEn] = useState<string>('');

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchResponses = async () => {

            try {
                const response = await fetch(`${ACTIVE_URL}/api/get_tc_text/`, {
                    method: 'GET',
                });
                if (response.ok) {
                    const data = await response.json();
                    setTermsAndConditionsContentGr(data.description_gr);
                    setTermsAndConditionsContentEn(data.description_en);
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        };
        fetchResponses();
    }, []);

    return (
        <div className='max-w-1128 m-auto pt-12 pb-10 px-4  1180:px-0 xmd:pt-20 xmd:pb-36 '>
            <HTMLRenderer htmlContent={activeLanguage === 'el' ? TermsAndConditionsContentGr : TermsAndConditionsContentEn} />
        </div>
    )
}

export default TermsAndConditionsContent;