import React from 'react';
import DefaultLanguageInterface from '../../interfaces';
import NewsHomeHeader from '../../Components/Misc/News/NewsHomeHeader/NewsHomeHeader';
import NewsAllNews from '../../Components/Misc/News/NewsAllNews/NewsAllNews';
import DownloadOurApp from '../../Components/Home/DownloadOurApp/DownloadOurApp';

const NewsHome:React.FC<DefaultLanguageInterface> = ({activeLanguage}) => {
    return (
        <div>
            <NewsHomeHeader activeLanguage={activeLanguage} />
            <NewsAllNews activeLanguage={activeLanguage} />
            <DownloadOurApp activeLanguage={activeLanguage} />
        </div>
    )
}

export default NewsHome;