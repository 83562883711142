import React, { useEffect, useRef, useState } from 'react';
import DefaultLanguageInterface from "../../../../interfaces";
import greekLanguage from '../../../../locales/gr.json';
import englishLanguage from '../../../../locales/en.json';
import { ACTIVE_URL } from '../../../../constants';
import HomeNewsItem from '../../../Home/HomeNews/HomeNewsItem/HomeNewsItem';
import { IonIcon } from '@ionic/react';

type NewsItem = {
    grTitle: string;
    enTitle: string;
    grDescription: string;
    enDescription: string;
    image: string;
    slug: string;
    date: string;
};

const NewsAllNews: React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
    const [newsItems, setNewsItems] = useState<NewsItem[]>([{} as NewsItem, {} as NewsItem, {} as NewsItem]);
    const fetched = useRef<boolean>(false);
    const [loadingItems, setLoadingItems] = useState<boolean>(true);
    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;
    const [currentFilter, setCurrentFilter] = useState<string>('descending_date');

    useEffect(() => {
        const fetchResponses = async () => {
            try {
                const response = await fetch(`${ACTIVE_URL}/api/get_all_news/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setNewsItems([])
                    let temp_arr: any = [];
                    data.map((item: any) => {
                        if (item.slug in temp_arr) return;
                        setNewsItems((prev) => [...prev, {
                            grTitle: item.gr_title,
                            enTitle: item.en_title,
                            grDescription: item.gr_description,
                            enDescription: item.en_description,
                            image: item.image_data,
                            date: item.date,
                            slug: item.slug
                        }]);
                        temp_arr.push(item.slug);
                    })
                    setLoadingItems(false);
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        }
        // Cleanup function
        if (!fetched.current) {
            fetchResponses();
            fetched.current = true;
        }
    }, []);

    useEffect(() => {
        const tempArr: any = [];
        newsItems.forEach((item, index) => {
            if (index === newsItems.length - 1) {
                if (tempArr.includes(item.slug)) {
                    setNewsItems(prev => prev.slice(0, -1));
                } else {
                    tempArr.push(item.slug);
                }
            }
        });
    }, [newsItems]);

    const sortItemsByDateAscending = () => {
        const sortedItems = [...newsItems].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        setNewsItems(sortedItems);
    }
    
    const sortItemsByDateDescending = () => {
        const sortedItems = [...newsItems].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        setNewsItems(sortedItems);
    }

    useEffect(() => {
        if (currentFilter === 'ascending_date') sortItemsByDateDescending();
        else if (currentFilter === 'descending_date') sortItemsByDateAscending();
    }, [currentFilter]);

    return (
        <div className='max-w-1128 m-auto mb-20 select-none px-4'>
            <div className='flex flex-col xmd:flex-row w-full justify-between items-start gap-2 xmd:items-center mb-6'>
                <h3 className='text-4xl text-neutral-800'>
                    {languageData.news.all_news_title}
                </h3>
                {currentFilter === 'ascending_date' ?
                    <button onClick={() => setCurrentFilter('descending_date')} className='flex items-center gap-1 xmd:gap-1.5 bg-neutral-600 text-white rounded-md px-2 py-1.5 xmd:px-3 xmd:py-2 text-sm xmd:text-[0.95rem] hover:bg-main duration-150'>
                        <IonIcon icon='calendar-clear' className='mb-0.5' />
                        <p>
                            {languageData.news.sort_by_date_desc}
                        </p>
                        <IonIcon icon='arrow-down-outline' />
                    </button>
                    :
                    <button onClick={() => setCurrentFilter('ascending_date')} className='flex items-center gap-1 xmd:gap-1.5 bg-neutral-600 text-white rounded-md px-2 py-1.5 xmd:px-3 xmd:py-2 text-sm xmd:text-[0.95rem] hover:bg-main duration-150'>
                        <IonIcon icon='calendar-clear' className='mb-0.5' />
                        <p>
                            {languageData.news.sort_by_date_asc}
                        </p>
                        <IonIcon icon='arrow-up-outline' />
                    </button>
                }
            </div>
            <div className="grid grid-cols-1 xmd:grid-cols-3 gap-4" >
                {newsItems.map((item, index) => (
                    <div key={index}>
                        <HomeNewsItem loading={loadingItems} grTitle={item.grTitle} enTitle={item.enTitle} grDescription={item.grDescription} enDescription={item.enDescription} image={item.image} slug={item.slug} date={item.date} activeLanguage={activeLanguage} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default NewsAllNews;