import rc from '../../images/driver_form/receipt.jpg'
import cl from '../../images/driver_form/car_licence.jpg'
import dl from '../../images/driver_form/driver_licence.jpg'
import dlc from '../../images/driver_form/driver_licence_card.png'
import dlc_back from '../../images/driver_form/driver_licence_card_back.png'
import id from '../../images/driver_form/id.png'
import id_back from '../../images/driver_form/id_back.png'
import bs from '../../images/driver_form/bank_statement.jpg'
import tx from '../../images/driver_form/tx.jpg'
import pi from '../../images/driver_form/driver_sample.jpg'

export type DriverInfo = {
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    city: string;
    receipt: File | null;
    edx_certificate: File | null;
    vehicle_registration: File | null;
    id_front: File | null;
    id_back: File | null;
    driver_license_front: File | null;
    driver_license_back: File | null;
    profile_image: File | null;
    bank_receipt: File | null;
    vehicle_image: File | null;
}


export type DriverInfoMetadata = {
    [Property in keyof DriverInfo]: {
        questionType: 'string' | 'phone' | 'city-select' | 'file' | 'boolean' | 'email';
        title: string;
        description: string;
        position: number;
        image_page?: string;
        questionText?: string;
        skippable?: boolean;
    };
};


export const driverInfoMetadataItems: DriverInfoMetadata = {
    email: { questionType: 'email', title: 'Διεύθυνση Email', description: 'Email Address', position: 0, skippable: false},
    phone_number: { questionType: 'phone', title: 'Αριθμός τηλεφώνου', description: 'Phone Number', position: 1, skippable: false },
    city: { questionType: 'city-select', title: 'Πόλη', description: 'City', position: 2, skippable: false },
    first_name: { questionType: 'string', title: 'Όνομα', description: 'Όνομα', position: 3, skippable: false },
    last_name: { questionType: 'string', title: 'Επώνυμο', description: 'Επώνυμο', position: 4, skippable: false },
    receipt: { questionType: 'file', title: 'Απόδειξη Ταμειακής Μηχανής', description: 'Receipt', position: 5, image_page:rc, skippable: false },
    edx_certificate: { questionType: 'file', title: 'Ειδική Άδεια Οδήγησης ΕΔΧ', description: 'EDX Certificate', position: 6, image_page:dl, skippable: false },
    vehicle_registration: { questionType: 'file', title: 'Άδεια Κυκλοφορίας', description: 'Vehicle Registration', position: 7, image_page:cl, skippable: false },
    id_front: { questionType: 'file', title: 'Μπροστινή Όψη Αστυνομικής Ταυτότητας / Διαβατηρίου', description: 'ID Front', position: 8, image_page:id, skippable: false },
    id_back: { questionType: 'file', title: 'Πίσω Όψη Αστυνομικής Ταυτότητας / Διαβατηρίου', description: 'ID Back', position: 9, image_page:id_back, skippable: false },
    driver_license_front: { questionType: 'file', title: 'Μπροστινή Όψη Διπλώματος Οδήγησης', description: 'Driver License Front', position: 10, image_page:dlc, skippable: false },
    driver_license_back: { questionType: 'file', title: 'Πίσω Όψη Διπλώματος Οδήγησης', description: 'Driver License Back', position: 11, image_page:dlc_back, skippable: false },
    profile_image: { questionType: 'file', title: 'Εικόνα Προφίλ', description: 'Profile Image', position: 12, image_page:pi, skippable: false },
    vehicle_image: { questionType: 'file', title: 'Φωτογραφία Οχήματος', description: '', position: 13, image_page:tx, skippable: false },
    bank_receipt: { questionType: 'file', title: 'Βεβαίωση Τραπεζικού Λογαριασμού', description: 'Bank Receipt', position: 14, image_page:bs, skippable: true },
    // personal_info_consent: { questionType: 'boolean', title: 'Άδεια Χρήσης Προσωπικών Δεδομένων', description: 'Με την εγγραφή σας, συμφωνείτε με τους Όρους Χρήσης και την Πολιτική Απορρήτου μας, δεσμεύεστε να συμμορφώνεστε με τις υποχρεώσεις σύμφωνα με την Ευρωπαϊκή Ένωση και την τοπική νομοθεσία και να παρέχετε μόνο νόμιμες υπηρεσίες και περιεχόμενο στην πλατφόρμα taxi.gr', position: 15, skippable: false },
};