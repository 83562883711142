import React from 'react';
import PressItem from '../PressItem/PressItem';
import DefaultLanguageInterface from '../../../interfaces';


interface Article {
    imageUrl: string;   // URL for the article's image
    title: string;      // Title of the article
    publisher: string;  // Publisher or domain of the article
    previewText: string;
    articleUrl: string;
}

const articles: Article[] = [
    {
        imageUrl: 'https://img.cnngreece.gr/img/1290/max/80/2023/08/29/Unknown.webp?t=6y7ReDCRS_zM4dnTLEp01w',
        title: 'taxi.gr: Ασφάλεια και κομψότητα στη νέα ελληνική εφαρμογή μετακίνησης που χρειάζεστε',
        publisher: 'CNN Greece',
        previewText: 'Δίπλα στον πελάτη, αλλά και στον οδηγό, με ποικιλία επιλογών ώστε καθένας να βρίσκει άμεσα αυτό ακριβώς που καλύπτει πλήρως τις ανάγκες του, κάθε δεδομένη στιγμή.',
        articleUrl: 'https://www.cnn.gr/oikonomia/epixeiriseis/story/379601/taxi-gr-asfaleia-kai-kompsotita-sti-nea-elliniki-efarmogi-metakinisis-pou-xreiazeste',
    },
    {
        imageUrl: 'https://www.businessdaily.gr/sites/default/files/styles/main_full/public/2023-09/taxigr.jpg?itok=s3fpA3DK',
        title: 'taxi.gr: Tο ελληνικό app που θέλει να μπει σφήνα σε Uber και Free Now',
        publisher: 'Business Daily',
        previewText: 'Έχοντας ήδη πάνω από 700 οδηγούς εγγεγραμμένους στον πρώτο μήνα λειτουργίας του, το Taxi.gr φιλοδοξεί να είναι μέσα στους επόμενους μήνες άμεσα ανταγωνιστικό προς Uber και Free Now.',
        articleUrl: 'https://www.businessdaily.gr/epiheiriseis/95787_taxigr-elliniko-app-poy-thelei-na-mpei-sfina-se-uber-kai-free-now',
    },
    {
        imageUrl: 'https://www.sportime.gr/wp-content/uploads/2023/11/taxi-taxi.gr-van-limo-taxitzis-app-efarmogi-metakinisi-metafora-exipiretisi-transfer-vip-travel-tour-1280x619.jpg',
        title: 'Taxi App: Δεν θες ταξί, θες taxi.gr',
        publisher: 'Sportime',
        previewText: 'Θέλεις ένα taxi, ένα Van ή μια Limo; Θέλεις με μετρητά, με κάρτα με Apple Pay ή Google Pay; Θες γρήγορα, αργά η προγραμματισμένα; Θέλεις απλά ένα Taxi ή Taxi.gr; Κατέβασε το app!!!',
        articleUrl: 'https://www.sportime.gr/engine-power/den-thes-taxi-thes-taxi-gr/',
    },
    {
        imageUrl: 'https://cdn.qumd.gr/img/1240/930/80/2023/08/29/0b81aee6-Stigmiotypo-o8onhs-2023-08-03-7.33.33-mm-2.webp?t=O8noN6mviLoPRo4D4i7fQg',
        title: 'taxi.gr: Ασφάλεια και κομψότητα στη νέα ελληνική εφαρμογή μετακίνησης που χρειάζεστε',
        publisher: 'Queen',
        previewText: 'Με συγκεκριμένα πλεονεκτήματα, τόσο για τους πελάτες της, όσο και για τους οδηγούς που επιλέγουν να συνεργαστούν μαζί της, η εφαρμογή taxi.gr διακρίνεται, σε σχέση με τον ανταγωνισμό.',
        articleUrl: 'https://www.queen.gr/culture/events-exodos/story/275335/taxi-gr-asfaleia-kai-kompsotita-sti-nea-elliniki-efarmogi-metakinisis-pou-xreiazeste',
    }

];

const PressAllPress: React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
    return (
        <div className='max-w-1128 m-auto mb-20 select-none px-4'>
            <div className="grid grid-cols-1 xmd:grid-cols-3 gap-4" >
                {articles.map((article, index) => (
                    <div key={index}>
                        <PressItem loading={false} key={index} {...article} activeLanguage={activeLanguage} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PressAllPress;
