import React from 'react';
import './ResponseRow.css';
import StatusCircle from '../StatusCyrcle/StatusCircle';
import { IonIcon } from '@ionic/react';

interface ResponseRowProps {
    status: "completed" | 'pending' | 'abandoned';
    name: string;
    dateCompleted: string;
    email: string;
    setSingleView: () => void;
}

const ResponseRow: React.FC<ResponseRowProps> = ({ name, email, dateCompleted, status, setSingleView }) => {
    let circleColor = '';

    switch (status) {
      case 'completed':
        circleColor = 'green';
        break;
      case 'pending':
        circleColor = 'orange';
        break;
      case 'abandoned':
        circleColor = 'red';
        break;
      default:
        circleColor = 'gray';
    }
    return (
        <div className='response-row-wrapper'>
            <div>{name}</div>
            <div>{email}</div>
            <div>{dateCompleted}</div>
            <div className='flex justify-start'><StatusCircle circleColorHex={circleColor} />{status.charAt(0).toUpperCase() + status.slice(1)}</div>
            <div onClick={() => setSingleView()} className='response-row-icon'><IonIcon icon='search-outline'></IonIcon> View</div>
        </div>
    );
};

export default ResponseRow;