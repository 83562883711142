import React, { ReactNode } from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import { useAuthContext } from '../utils/useAuthContext';

export type PrivateRouteProps = {
    authenticationPath: string;
    children: ReactNode;
} & RouteProps;

const PrivateRoute: React.FC<PrivateRouteProps> = ({ authenticationPath, children }) => {
    const { user } = useAuthContext();
    return user ? (children as React.ReactElement) : <Navigate to={{ pathname: authenticationPath }} />;
};

export default PrivateRoute;