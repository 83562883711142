import React, { useState } from 'react';
import AdminEmailActionTemplate from './AdminEmailActionTemplate/AdminEmailActionTemplate';
import AdminActionAddNote from './AdminActionAddNote/AdminActionAddNote';
import AdminActionsAddTags from './AdminActionsAddTags/AdminActionsAddTags';

type TagType = {
    id: string;
    name: string;
}

interface emailDataProps {
    type?: 'email';
    from: string;
    title: string;
    email: string;
    visibleTo: string | string[];
}

interface noteDataProps {
    type?: 'note';
    note: string,
    ccGroup: string,
}

interface tagsDataProps {
    type?: 'tags';
    activeTags: TagType[],
    visibilityGroup: string,
}

type ActionTypes = emailDataProps | noteDataProps | tagsDataProps;

interface AdminActionsProps {
    initialAction: ActionTypes;
    closeAction: () => void;
    setStatusActionData: (actionData: emailDataProps | noteDataProps | tagsDataProps) => void;
    className?: string;
}

const AdminActions: React.FC<AdminActionsProps> = ({ initialAction, closeAction, className, setStatusActionData }) => {

    const [acitonData, setActionData] = useState<emailDataProps | noteDataProps | tagsDataProps>(initialAction);

    const handleActionDataChange = (newActionData: emailDataProps | noteDataProps | tagsDataProps) => {
        setActionData(newActionData);
        setStatusActionData(newActionData)
    }

    return (
        <div className={`${className}`}>
            {acitonData.type === 'email' && (
                <div>
                    <AdminEmailActionTemplate closeAction={() => closeAction()} setActionData={handleActionDataChange} className='bg-white'/>
                </div>
            )}
            {acitonData.type === 'note' && (
                <div>
                    <AdminActionAddNote closeAction={() => closeAction()} className='bg-white' setActionData={handleActionDataChange} />
                </div>
            )}
            {acitonData.type === 'tags' && (
                <div>
                    <AdminActionsAddTags closeAction={() => closeAction()} className='bg-white' setActionData={handleActionDataChange} />
                </div>
            )}
        </div>
    )
}


export default AdminActions;