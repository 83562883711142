import * as React from "react";
import { format, parseISO } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { cn } from "../../../../@/lib/utils";
import { Button } from "../../../../@/components/ui/button";
import { Calendar } from "../../../../@/components/ui/calendar";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "../../../../@/components/ui/popover";

interface SimpleDatePickerProps {
    onDateChange: (date:string) => void;
    className?: string;
    selectedDate?: string;
}

export function DatePicker({ onDateChange, selectedDate }: SimpleDatePickerProps) {
    const today = new Date();
    const [date, setDate] = React.useState<Date | undefined>(selectedDate ? parseISO(selectedDate) : today);
    React.useEffect(() => {
      // Update the date state when selectedDate prop changes
      if (selectedDate) {
          const parsedDate = parseISO(selectedDate);
          setDate(parsedDate);
      }
  }, [selectedDate]);
    React.useEffect(() => {
        if (date) {
            onDateChange(format(date, "yyyy-MM-dd HH:mm"));
        }
    }, [date])

    return (
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant={"outline"}
              className={cn(
                "w-[280px] justify-start text-left font-normal",
                !date && "text-muted-foreground"
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {date ? format(date, "PPP") : <span>Pick a date</span>}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0">
            <Calendar
              mode="single"
              selected={date}
              onSelect={setDate}
              initialFocus
            />
          </PopoverContent>
        </Popover>
    
    );
}
