import React from 'react';
import ContactHeader from '../../Components/Contact/ContactHeader/ContactHeader';
import ContactInfo from '../../Components/Contact/ContactInfo/ContactInfo';
import DefaultLanguageInterface from '../../interfaces';

const Contact: React.FC<DefaultLanguageInterface> = ({ }) => {
    return (
        <div>
            <ContactHeader />
            <ContactInfo />
        </div>
    )
};

export default Contact;