import AdminMainHeader from "../AdminMainHeader/AdminMainHeader";
import AdminRoundImageMain from "./AdminRoundImage/AdminRoundImageMain";


const Microapps = () => {


    return (
        <div>
            <AdminMainHeader pageTitle="Microapps" />
            <AdminRoundImageMain />
        </div>
    )
}

export default Microapps;