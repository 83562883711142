import React, { useRef, useEffect, useState } from 'react';
import img1 from '../../../images/backgrounds/love_the_ride_2.jpg';
import ltr from '../../../images/backgrounds/love_the_ride_1.jpg';
import greekLanguage from '../../../locales/gr.json';
import englishLanguage from '../../../locales/en.json';
import ActionButton from '../../ActionButton/ActionButton';
import QrPopup from '../HomeHero/QrPopup/QrPopup';


interface HomeLoveTheRideProps {
    activeLanguage: string;
}

const HomeLoveTheRide: React.FC<HomeLoveTheRideProps> = ({ activeLanguage }) => {
    const elementRef = useRef<HTMLDivElement>(null);
    const [_, setIsVisible] = useState(false);
    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;
    const [qrPopup, setQrPopup] = useState(false);
    
    const toggleQrPopup = () => {
        setQrPopup(!qrPopup);
    }

    useEffect(() => {
        // Check if the element has been seen before using localStorage
        const hasBeenSeen = localStorage.getItem('hasBeenSeenHomeLoveTheRide');

        if (!hasBeenSeen) {
            const observer = new IntersectionObserver(
                (entries) => {
                    const [entry] = entries;
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        // Set the flag to indicate that the element has been seen
                        localStorage.setItem('hasBeenSeenHomeLoveTheRide', 'true');
                    }
                },
                {
                    root: null,
                    rootMargin: '0px',
                    threshold: 0.5, // Adjust the threshold value as needed (0 to 1)
                }
            );
            if (elementRef.current) {
                observer.observe(elementRef.current);
            }
            return () => {
                if (elementRef.current) {
                    observer.unobserve(elementRef.current);
                }
            };
        } else {
            // If the element has been seen before, set isVisible to true immediately
            setIsVisible(true);
        }
    }, []);

    return (
        <div className="max-w-1128 m-auto px-4 1180:px-0 py-10 xmd:pt-24 pb-24">
            <div className='grid grid-cols-1 xmd:grid-cols-2 gap-x-10 items-start '>
                <div className='flex flex-col justify-center h-full px-2'>
                    <h3 className='text-4xl xmd:text-[2.5rem] mb-4 xmd:mb-8 text-neutral-800 font-semibold'>{languageData.home.home_love_the_ride_title}</h3>
                    <p className='text-neutral-800 mb-4'>{languageData.home.home_love_the_ride_subtitle}</p>
                    <div className='relative xmd:hidden flex w-full  max-w-[90vw] gap-[3%] items-center pt-2 pb-5 z-0'>
                        <img src={ltr} alt="" className='w-[40%] rounded-lg z-0' />
                        <img src={img1} alt="" className='w-[57%] rounded-lg z-0' />
                    </div>
                    <div className='mb-2'>
                        <div className='flex gap-4'>
                            <div className='ion-icon-default-transform bg-main text-sm h-6 w-6 items-center flex justify-center rounded-full text-white'>
                                1
                            </div>
                            <h4 className='text-neutral-900 text-lg pb-1'>
                                {languageData.home.home_love_the_ride_1_title}
                            </h4>
                        </div>
                        <div className='max-w-20 text-sm pl-10 text-neutral-600'>
                            {languageData.home.home_love_the_ride_1_text}
                        </div>
                    </div>
                    <div className='mb-2'>
                        <div className='flex gap-4'>
                            <div className='ion-icon-default-transform bg-main text-sm h-6 w-6 items-center flex justify-center rounded-full text-white'>
                                2
                            </div>
                            <h4 className='text-neutral-900 text-lg pb-1'>
                                {languageData.home.home_love_the_ride_4_title}
                            </h4>
                        </div>
                        <div className='max-w-20 text-sm pl-10 text-neutral-600'>
                            {languageData.home.home_love_the_ride_4_text}
                        </div>
                    </div>
                    <div className='mb-2'>
                        <div className='flex gap-4'>
                            <div className='ion-icon-default-transform bg-main text-sm h-6 w-6 items-center flex justify-center rounded-full text-white'>
                                3
                            </div>
                            <h4 className='text-neutral-900 text-lg pb-1'>
                                {languageData.home.home_love_the_ride_2_title}
                            </h4>
                        </div>
                        <div className='max-w-20 text-sm pl-10 text-neutral-600'>
                            {languageData.home.home_love_the_ride_2_text}
                        </div>
                    </div>
                    <div >
                    <ActionButton activeLanguage={activeLanguage} className={"inline-block  btn btn-primary bg-main py-2 px-4 rounded-full text-white duration-100 mt-4"} text_english={'Get the app'} text_greek={'Κατέβασε την εφαρμογή'} onClick={toggleQrPopup} />
                    </div>
                </div>
                <div className='relative hidden xmd:flex w-full h-full justify-center max-w-[560px] gap-[3%] items-center z-0'>
                    <img src={ltr} alt="" className='w-[40%] rounded-lg z-0' />
                    <img src={img1} alt="" className='w-[57%] rounded-lg z-0' />
                </div>
            </div>
            {qrPopup && <QrPopup onClose={() => setQrPopup(false)} activeLanguage={activeLanguage} forProp='passenger' header_text={languageData.home.home_popup_header} body_text={languageData.home.home_popup_text} />}
        </div>
    );
};

export default HomeLoveTheRide;