import React from 'react';
import DefaultLanguageInterface from '../../../../interfaces';
import './TermsAndConditionsHero.css'
import greekLanguage from '../../../../locales/gr.json';
import englishLanguage from '../../../../locales/en.json';

const TermsAndConditionsHero:React.FC<DefaultLanguageInterface> = ({activeLanguage}) => {
    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;
    return (
        <div className="tc_header-container bg-center flex  pl-4 1240:pl-0">
            <div className="max-w-1128 animate-fadeUp w-full h-full flex flex-col justify-end text-white m-auto xmd:justify-center">
                <div className='text-4xl font-semibold xmd:text-6xl animate-fadeUp xmd:pb-6 pb-5 leading-tight xmd:max-w-[520px]'>{languageData.tc.header_title}</div>
                <div className='delay-75 animate-fadeUp mb-4 xmd:mb-6 font-thin text-lg leading-8 w-full xmd:min-w-[400px] xmd:w-[32vw]'>
                    {languageData.tc.header_subtitle}
                </div>
                <div className='button_div delay-150 animate-fadeUp scale-0.75 mb-[6vh]'>

                </div>
            </div> 
        </div>
    )
}

export default TermsAndConditionsHero;