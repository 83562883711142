import './CandidateRows.css'

const CandidtatesTableHeader = () => {

    return (
        <div className='candidates_table_row border-gray-400 bg-gray-200 rounded-t-sm'>

            <div className='h-10 flex items-center px-2'>
                Name
            </div>
            <div className='h-10 flex items-center border-gray-400 px-2 border-l'>
                Stage
            </div>
            <div className='h-10 flex items-center px-2 border-gray-400 border-l'>
                Job Fit Score
            </div>
            <div className='h-10 flex items-center px-2 border-gray-400 border-l'>
                Date Created
            </div>
        </div>
    )

}

export default CandidtatesTableHeader;