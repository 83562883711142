import React, { useEffect, useState } from 'react';
import { isValidPhoneNumber } from '../../../utils/InputField/InputFieldValidation';

interface DriverFormPhoneProps {
    sendPhone: (phone: string) => void;
    preSelectedPhone?: string;
}

const DriverFormPhone: React.FC<DriverFormPhoneProps> = ({ sendPhone, preSelectedPhone = '' }) => {
    const [phone, setPhone] = useState<string>(preSelectedPhone);
    const [phoneFieldTouched, setPhoneFieldTouched] = useState(false);
    const [isPhoneFieldFocused, setIsPhoneFieldFocused] = useState(false);

    useEffect(() => {
        // Update the phone state whenever the preSelectedPhone prop changes
        setPhone(preSelectedPhone);
    }, [preSelectedPhone]);

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedPhone = event.target.value;
        setPhone(updatedPhone);
        // Send the updated phone back to the parent component only if it's valid
        sendPhone(isValidPhoneNumber(updatedPhone) ? updatedPhone : '');
    };

    const phoneBlurHandler = () => {
        setPhoneFieldTouched(true);
        setIsPhoneFieldFocused(false);
    }

    return (
        <div>
            <input
                type="tel"
                id='tel'
                placeholder='Αριθμός κινητού'
                onBlur={() => phoneBlurHandler()}
                className={`w-full text-[0.95rem] h-12 px-2 text-black rounded-md outline-none bg-neutral-100 border ${isValidPhoneNumber(phone) && phoneFieldTouched ? 'border-green-400' : (!phoneFieldTouched ? 'border-neutral-200' : (!isPhoneFieldFocused ? 'border-red-500' : ''))} focus:pl-[6.68px] focus:border-2 focus:border-main focus:shadow-md`}
                value={phone}
                onInput={(e) => {
                    const target = e.target as HTMLInputElement;
                    let value = target.value.replace(/\D+/g, '');
                    if (value.length > 10) {
                        value = value.substring(0, 10);
                    }
                    target.value = value;
                }}
                onFocus={() => setIsPhoneFieldFocused(true)}
                onChange={(e) => handlePhoneChange(e)}
            />
            {!isValidPhoneNumber(phone) && phoneFieldTouched && (
                <div className="text-xs text-red-500 mt-1 ml-1">
                    Παρακαλώ εισάγετε έναν έγκυρο αριθμό τηλεφώνου.
                </div>
            )}
        </div>
    );
};

export default DriverFormPhone;
