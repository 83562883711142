import { useState } from 'react';
import AdminMainHeader from '../AdminMainHeader/AdminMainHeader';
import { useAuthContext } from '../../../utils/useAuthContext';
import AdminUserGroups from './AdminUserGroups/AdminUserGroups';
import AdminUserManagement from './AdminUserManagement/AdminUserManagement';

const AdminUsers = () => {
    const [users, setUsers] = useState([]);
    const { authTokens } = useAuthContext();

    // useEffect(() => {
    //     const fetchUsers = async () => {
    //         if (!authTokens || !authTokens.access) {
    //             return;
    //         }
    //         try {
    //             const response = await fetch(`${ACTIVE_URL}/api/get_users/`, {
    //                 method: 'GET',
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'Authorization': `Bearer ${authTokens.access}`,
    //                 }
    //             });
    //             if (response.ok) {
    //                 const data = await response.json();

    //                 setUsers(data);
    //             } else {
    //                 console.error('Failed to fetch responses');
    //             }
    //         } catch (error) {
    //             console.error('Error fetching responses:', error);
    //         }
    //     };

    //     fetchUsers();
    // }, []);

    return (
        <div>
            <AdminMainHeader pageTitle='User Management' />
            <AdminUserGroups />
            <AdminUserManagement />
        </div>
    )
}



export default AdminUsers;