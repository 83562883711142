import React from "react";
import { Link } from "react-router-dom"
import DefaultLanguageInterface from "../../../interfaces";
import greekLanguage from '../../../locales/gr.json';
import englishLanguage from '../../../locales/en.json';
import DownloadOurApp from "../../../Components/Home/DownloadOurApp/DownloadOurApp";
import notFoundImg from "../../../images/404.jpg"

const NotFound: React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;
    return (
        <div className="max-w-1128 mx-auto pt-12">
            <div
                className="md:h-[90vh] h-[70vh] max-h-[700px] md:min-h-[500px] my-6 mx-4 py-2 px-4 rounded-xl max-w-[1128px] bg-cover bg-[position:28%_100%] sm:bg-[position:20%_30%] md:bg-[position:40%_30%] relative overflow-hidden md:gap-x-4"
                style={{
                    backgroundImage: `url(${notFoundImg})`,
                }}
            >
                <div className="absolute inset-0 bg-black opacity-30 rounded-md"></div>

                <div className="relative z-10 flex flex-col items-center md:items-end justify-end md:justify-center h-full pb-4">
                    <div className="w-full md:w-1/2 flex flex-col items-center">
                        <h1 className="text-6xl text-white font-semibold mb-5">{languageData[404].title}</h1>
                        <p className="text-lg text-white mb-5 md:max-w-[70%] max-w-[90%] text-center">{languageData[404].subtitle}</p>
                        <Link
                            to={`${activeLanguage === 'en' ? '/en' : ''}/passenger`}
                            className="text-white btn btn-primary bg-main py-[0.565rem] px-6 rounded-full hover:bg-blue-700 duration-100"
                        >
                            {languageData[404].button}
                        </Link>
                    </div>
                </div>
            </div>
            <DownloadOurApp activeLanguage={activeLanguage} />
        </div>
    )
}

export default NotFound;