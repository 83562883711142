import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ACTIVE_URL } from '../../constants';
import DefaultLanguageInterface from '../../interfaces';
import HTMLRenderer from '../../utils/HTMLRenderer';
import SkeletonLoader from '../../utils/SkeletonLoader/SkeletonLoader';

type NewsItem = {
    grTitle: string;
    enTitle: string;
    grDescription: string;
    enDescription: string;
    image: string;
    date: string;
};

const SingleNewsPage: React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
    const { slug } = useParams();
    const [newsItem, setNewsItem] = useState<NewsItem>({ grTitle: '', enTitle: '', grDescription: '', enDescription: '', image: '', date: '' });
    const loading = useRef(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchResponses = async () => {
            try {
                const response = await fetch(`${ACTIVE_URL}/api/get_news/${slug}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                
                if (response.ok) {
                    const data = await response.json();

                    setNewsItem({
                        grTitle: data.gr_title,
                        enTitle: data.en_title,
                        grDescription: data.gr_description,
                        enDescription: data.en_description,
                        image: data.image_data,
                        date: data.date
                    });
                    loading.current = false;
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        }
        fetchResponses();
    }, []);

    return (
        <div className='pt-16'>
            {loading.current ?
                <div className='max-w-1128 m-auto'>
                    <SkeletonLoader height={'54vh'} width={'1128px'} borderRadius={14} />
                </div>
                :
                <div className='relative  text-white max-w-1128 mx-auto rounded-2xl '>
                    <img src={`data:image/jpeg;base64,${newsItem.image}`} alt="test" className='rounded-md' />
                    <div className='absolute z-0 inset-0 bg-black bg-opacity-40 rounded-md'></div>
                    <div className='absolute inset-0  h-full flex justify-center flex-col px-10'>
                        <div className='text-4xl font-semibold xmd:text-6xl animate-fadeUp xmd:pb-4 pb-2 leading-tight xmd:max-w-[520px]'>{activeLanguage === 'el' ? newsItem?.grTitle : newsItem?.enTitle}</div>
                        <div className='delay-75 animate-fadeUp mb-4 xmd:mb-6 font-thin text-lg leading-8 w-full xmd:min-w-[400px] xmd:w-[32vw]'>
                            {newsItem?.date ? new Date(newsItem.date).toLocaleDateString('en-GB') : ''}
                        </div>
                    </div>

                </div>

            }
            <div className='max-w-1128 m-auto xmd:pt-6 xmd:pb-28 min-h-[40vh]'>
                {loading.current ?
                    <div className='flex flex-col gap-1.5'>
                        <SkeletonLoader height={'16px'} width={'1128px'} borderRadius={4} />
                        <SkeletonLoader height={'16px'} width={'1128px'} borderRadius={4} />
                        <SkeletonLoader height={'16px'} width={'1128px'} borderRadius={4} />
                        <SkeletonLoader height={'16px'} width={'1128px'} borderRadius={4} />
                        <SkeletonLoader height={'16px'} width={'1128px'} borderRadius={4} />
                        <SkeletonLoader height={'16px'} width={'1128px'} borderRadius={4} />
                        <SkeletonLoader height={'16px'} width={'68%'} borderRadius={4} />
                    </div>
                    :
                    <HTMLRenderer htmlContent={activeLanguage === 'el' ? newsItem?.grDescription : newsItem?.enDescription} />
                }
            </div>
        </div>
    )


}

export default SingleNewsPage;