import React, { useEffect, useState } from 'react';
import { IonIcon } from '@ionic/react';

import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../../../../@/components/ui/select";

type TagType = {
    id: string;
    name: string;
}

interface emailDataProps {
    type?: 'email';
    from: string;
    title: string;
    email: string;
    visibleTo: string | string[];
}

interface noteDataProps {
    type?: 'note';
    note: string,
    ccGroup: string,
}

interface tagsDataProps {
    type?: 'tags';
    activeTags: TagType[],
    visibilityGroup: string,
}

type ActionTypes = emailDataProps | noteDataProps | tagsDataProps;

interface AdminSettingsStatusActiveFooterProps {
    cancelStatusAdd: () => void;
    saveStatus: () => void;
    saveAndAddAction?: () => void;
    saveAvailability: boolean;
    addNewAction: (action: ActionTypes) => void;
}

const AdminSettingsStatusActiveFooter: React.FC<AdminSettingsStatusActiveFooterProps> = ({ cancelStatusAdd, addNewAction, saveAvailability, saveStatus }) => {
    
    const [localSelectedAddAction, setLocalSelectedAction] = useState<string | undefined>(undefined);
    const [resetSelectKey, setResetSelectKey] = useState(0);

    const initialEmailProps: emailDataProps = {
        type: 'email',
        from: '',
        title: '',
        email: '',
        visibleTo: '',
    };

    const intitialNote: noteDataProps = {
        type: 'note',
        note: '',
        ccGroup: '',
    }

    const initialTags: tagsDataProps = {
        type: 'tags',
        activeTags: [],
        visibilityGroup: '',
    }

    const handleAddAction = (option: string | undefined) => {
        if (typeof (option) === 'string') {
            if (option === 'email') {
                addNewAction(initialEmailProps);
                setLocalSelectedAction(undefined);
                setResetSelectKey(prevKey => prevKey + 1);
            }
            if (option === 'note') {
                addNewAction(intitialNote);
                setLocalSelectedAction(undefined);
                setResetSelectKey(prevKey => prevKey + 1);
            }
            if (option === 'tags') {
                addNewAction(initialTags);
                setLocalSelectedAction(undefined);
                setResetSelectKey(prevKey => prevKey + 1);
            }
        }
    }

    const handleSaveStatusFooter = () => {
        console.log('saveStatusFooter')
        saveStatus();
    }

    useEffect(() => {
        console.log(localSelectedAddAction)
    }, [localSelectedAddAction])

    return (
        <div className='mt-4 flex flex-col gap-4'>
            <div className='flex gap-1 items-center'>
                <Select
                    onValueChange={(option) => handleAddAction(option)}
                    value={undefined}
                    key={resetSelectKey}
                >
                    <SelectTrigger className="w-[180px] focus:outline-none flex gap-1 justify-between">
                        <div className='flex gap-2'>
                            <IonIcon icon="flash" className='ion-icon-default-transform text-main' />
                            <SelectValue placeholder={`Add Action`} className='' />
                        </div>
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            <SelectItem key="select-add-action-1" value="email" className='flex items-center outline-none' >
                                Send Email
                            </SelectItem>
                            <SelectItem key="select-add-action-2" value="note" className='flex items-center outline-none' >
                                Add Note
                            </SelectItem>
                            <SelectItem key="select-add-action-3" value="tags" className='flex items-center outline-none' >
                                Add Tags
                            </SelectItem>
                        </SelectGroup>
                    </SelectContent>
                </Select>
            </div>
            <div className='flex justify-between'>
                {saveAvailability && (
                    <div>
                        <div className='flex gap-2 '>
                            <div className='bg-main hover:bg-blue-500 text-white cursor-pointer h-10 rounded-md py-2 px-4 justify-center items-center' onClick={handleSaveStatusFooter}>
                                Save
                            </div>
                            <div className='bg-gray-400 hover:bg-green-500 cursor-pointer h-10 text-white rounded-md py-2 px-4 justify-center items-center'>
                                Save and add another
                            </div>
                        </div>
                    </div>
                )}
                {!saveAvailability && (
                    <div>
                        <div className='flex gap-2 '>
                            <div className='bg-gray-400 text-white rounded-md h-10 py-2 px-4 justify-center items-center'>
                                Save
                            </div>
                            <div className='text-gray-400 border border-gray-400 h-10 rounded-md py-2 px-4 justify-center items-center'>
                                Save and add another
                            </div>
                        </div>
                    </div>
                )}
                <div>
                    <div className='bg-gray-700 cursor-pointer hover:bg-red-500 rounded-md py-2 px-4 text-white' onClick={cancelStatusAdd}>
                        Cancel
                    </div>
                </div>
            </div>
        </div>
    )
}


export default AdminSettingsStatusActiveFooter;