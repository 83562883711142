import * as React from "react"

import { cn } from "../../lib/utils";

import { IonIcon } from "@ionic/react";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> { selectedFile?: File | null }

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    if (type === "file") {
      const display_text = 'Επιλογή Φωτογραφίας'
      return (
        <label className="flex flex-col items-center w-full bg-white text-primary rounded-md border border-input file:bg-transparent file:text-primary file:text-sm file:font-medium file:cursor-pointer file:py-2 file:px-3 file:rounded-md file:border-dashed file:border-input file:border-2">
          <span className="text-sm file:text-sm file:font-medium file:text-muted-foreground py-[9px] text-left w-full px-4 cursor-pointer flex justify-between items-center">

            {
              props.selectedFile ? (
                <div>
                  Επιλεγμένο Αρχείο: {props.selectedFile.name}
                </div>

              ) :
                <div className="flex gap-2 items-center justify-center">
                  <IonIcon icon='camera' className=' text-xl' />
                  {display_text}
                </div>}

            {
              props.selectedFile ? (

                <IonIcon icon='checkmark' className="text-totalGreen text-xl" />

              ) : null
            }

          </span>
          <input
            type="file"
            className="hidden"
            ref={ref}
            {...props}
          />
        </label>
      )
    }
    return (
      <input
        type={type}
        className={cn(
          "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm  file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground  disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        ref={ref}
        {...props}
      />
    )
  }
)
Input.displayName = "Input"

export { Input }
