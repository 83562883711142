import React, { useState } from 'react';
import WhyUsSquare from './WhyUsSquare/WhyUsSquare';

interface WhyUsProps {
    activeLanguage: string;
}

const WhyUs:React.FC<WhyUsProps> = ({activeLanguage}) => {
    const why_us_squares = [
        {
            title_text_gr: 'Κέρδισε έως και 7€ ανά διαδρομή',
            title_text_en: 'Earn up to 7€ per ride',
            content_text_gr: 'Οδήγησε καθημερινά μαζί μας και κέρδισε επιπλέον χρήματα για κάθε σου διαδρομή.',
            content_text_en: 'As long as the customer chooses the category your vehicle belongs to.',
            ion_icon: 'cash-outline',
        },
        {
            title_text_gr: '24/7 Υποστήριξη',
            title_text_en: '24/7 Support',
            content_text_gr: 'Είμαστε δίπλα σου όταν μας χρειάζεσαι.',
            content_text_en: 'Invaluable peace of mind when you need it most.',
            ion_icon: 'call-outline',
        },
        {
            title_text_gr: 'Με μέγιστη ευελιξία',
            title_text_en: 'Maximum Flexibility',
            content_text_gr: 'Εργάσου ελεύθερα όποτε το επιθυμείς, αυξάνοντας τα ημερήσια έσοδά σου.',
            content_text_en: 'No mandatory branding, no scheduling commitments. Work and make money on your own terms.',
            ion_icon: 'car-sport-outline',
        },

        {
            title_text_gr: 'Χωρίς αποκλειστικότητες',
            title_text_en: 'Non-exclusive',
            content_text_gr: 'Σε σεβόμαστε και σε καταλαβαίνουμε. Δεν σε δεσμεύουμε με αποκλειστικότητα και εργαζόμαστε για την προστασία των συμφερόντων σου.',
            content_text_en: 'You are respected and understood. We do not tie you down to exclusivity and work to protect your interests.',
            ion_icon: 'people-outline',
        },
        {
            title_text_gr: 'Άμεση ζήτηση και ραντεβού',
            title_text_en: 'On demand and scheduled rides',
            content_text_gr: 'Επωφελήσου από τις άμεσες κρατήσεις και τα ραντεβού, παρέχοντας παράλληλα στους επιβάτες σου ηρεμία και ασφάλεια.',
            content_text_en: 'Apart from ASAP bookings, benefit from pre-bookings whilst giving your clients peace of mind when they need it most.',
            ion_icon: 'time-outline',
        },
        {
            title_text_gr: 'Άφησε την εφαρμογή να σε οδηγήσει',
            title_text_en: 'Let the app lead you',
            content_text_gr: 'Συνδεθείτε, αρχίστε να δέχεστε διαδρομές και λάβετε αναλυτικές οδηγίες πλοήγησης με την εκπληκτική εφαρμογή οδηγού.',
            content_text_en: 'Log-in, start accepting trips and get turn-by-turn directions with our awesome driver’s app.',
            ion_icon: 'navigate-outline',
        },

    ]


    return (
        <div className='pt-4 '>

            <div className='grid gap-x-2 xmd:gap-x-4 gap-y-4 grid-cols-2 xmd:grid-cols-3 max-w-1128 m-auto'>
                {/* Map each why square to a component */}
                {why_us_squares.map((why_us_square, index) => (
                    <WhyUsSquare 
                        key={index}
                        title={activeLanguage === 'el' ? why_us_square.title_text_gr : why_us_square.title_text_en}
                        content_text={activeLanguage === 'el' ? why_us_square.content_text_gr : why_us_square.content_text_en}
                        ion_icon={why_us_square.ion_icon}
                    />
                ))}
            </div>
        </div>
    )

}

export default WhyUs;