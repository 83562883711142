import AdminBox from "../../Helpers/AdminBox/AdminBox";
import AdimBoxTitle from "../../Helpers/AdminBoxTitle/AdimBoxTitle";
import AdminRoundImage from "./AdminRoundImage";
import AdminRoundImageRow from "./AdminRoundImageRow";

const AdminRoundImageMain = () => {
    return (
        <div>
            <AdminBox >
                <AdimBoxTitle title='Image Uploader' />
                <div className="px-4 py-2">
                    <h3 className="text-xl">Image List</h3>
                    <div className="flex flex-col gap-2">
                        <AdminRoundImageRow />
                    </div>
                </div>

                <AdminRoundImage />
            </AdminBox>
        </div>
    )
}

export default AdminRoundImageMain;
