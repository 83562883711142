import React from 'react';
import DriverFormFile from '../DriverFormFile/DriverFormFile';
import DriverFormEmail from '../DriverFormEmail/DriverFormEmail';
import DriverFormPhone from '../DriverFormPhone/DriverFormPhone';
import DriverFormCitySelect from '../DriverFormSelect/DriverFormSelect';
import { DriverInfo, driverInfoMetadataItems } from '../DriverFormMetadata';

interface QuestionRendererProps {
  currentQuestionKey: keyof DriverInfo;
  driverInfo: DriverInfo;
  handleInputChange: (key: keyof DriverInfo, value: any) => void;
}

const QuestionRenderer: React.FC<QuestionRendererProps> = ({
  currentQuestionKey,
  driverInfo,
  handleInputChange,
}) => {
  //line 80: const [isOpen, setIsOpen] = React.useState(false);
  const metadata = driverInfoMetadataItems[currentQuestionKey];
  const value = driverInfo[currentQuestionKey];

  switch (metadata.questionType) {
    case 'string':
      return (
        <div className='flex flex-col gap-2'>
          <div className='text-white text-lg'>{metadata.title}</div>
          <input
            type="text"
            className={`w-full text-large py-2 pl-2 pr-2 text-neutral-900 rounded-md outline-none bg-neutral-100 border focus:shadow-md`}
            placeholder={metadata.description}
            value={value as string || ''}
            onChange={(e) => handleInputChange(currentQuestionKey, e.target.value)}
          />
        </div>
      );
    case 'phone':
      return (
        <div className='w-full flex flex-col gap-1'>
          <div className='text-white text-lg'>{metadata.title}</div>
          <DriverFormPhone
            sendPhone={(phone) => handleInputChange(currentQuestionKey, phone)}
            preSelectedPhone={driverInfo[currentQuestionKey] as string}
          />
        </div>
      );
    case 'city-select':
      return (
        <div className='flex flex-col gap-2'>
          <div className='text-white text-lg'>{metadata.title}</div>
          <DriverFormCitySelect
            selectedCity={value as string}
            setSelectedCity={(city: string) => handleInputChange(currentQuestionKey, city)}
          />
        </div>
      );
    case 'email':
      return (
        <div className='w-full flex flex-col gap-1'>
          <div className='text-white text-lg'>{metadata.title}</div>
          <DriverFormEmail
            sendEmail={(email) => handleInputChange(currentQuestionKey, email)}
            preSelectedEmail={driverInfo[currentQuestionKey] as string}
          />
        </div>
      );
      case 'file':
        return (
          <div className='flex flex-col gap-2 h-full pt-2'>
            <div className='flex items-center w-full justify-center mt-8'>
              <img
                src={metadata.image_page}
                alt={metadata.title}
                className={`w-auto object-contain rounded-md ${
                  driverInfo[currentQuestionKey] ? 'max-h-[17.5vh]' : 'max-h-[30vh]'
                }`}
              />
            </div>
            <div className='text-white text-lg flex items-center justify-center gap-2'>
              <div className='text-center'>{metadata.title}</div>
              {/* <Dialog open={isOpen} onOpenChange={setIsOpen}>
                <DialogTrigger asChild>
                  <div className='text-base cursor-pointer flex items-center justify-center gap-1'>
                    <IonIcon icon='document-text' className='text-neutral-100' />
                    Δείγμα
                  </div>
                </DialogTrigger>
                <DialogContent className="sm:max-w-[425px] max-w-[94vw] rounded-md sm:mx-0 bg-neutral-100">
                  <DialogHeader>
                    <DialogTitle>Δείγμα: {metadata.title}</DialogTitle>
                  </DialogHeader>
                  <div className="mt-4 flex justify-center">
                    <img 
                      src={metadata.image_page} 
                      alt={metadata.title} 
                      className='w-auto max-h-[40vh] rounded-sm object-contain'
                    />
                  </div>
                </DialogContent>
              </Dialog> */}
            </div>
            <DriverFormFile
              key={currentQuestionKey}
              sendFile={(file) => handleInputChange(currentQuestionKey, file)}
              preSelectedFile={driverInfo[currentQuestionKey] as File | null}
            />
          </div>
        );

    default:
      return null;
  }
};

export default QuestionRenderer;