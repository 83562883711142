import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../../../../utils/useAuthContext';
import AdimBoxTitle from '../../Helpers/AdminBoxTitle/AdimBoxTitle';
import AdminBox from '../../Helpers/AdminBox/AdminBox';
import { ACTIVE_URL } from '../../../../constants';
import { IonIcon } from '@ionic/react';
import AdminAddSettingsStatus from './AdminAddSettingsStatus/AdminAddSettingsStatus';


interface Category {
    id: number;
    title: string;
    description: string;
    priority: number;
}

interface Stage {
    id: number;
    title: string;
    priority: number;
    category: number;
    timelimit: number;
}

const AdminSettingsStatusManagement: React.FC = () => {
    const { authTokens } = useAuthContext();
    const [view, setView] = useState<string>('overview');
    const [statusColors, setStatusColors] = useState<[]>([]);
    const [stageData, setStageData] = useState<{ categories: Category[], stages: Stage[] }>({ categories: [], stages: [] });
    const [categorizedStages, setCategorizedStages] = useState<{ [categoryId: number]: Stage[] }>({});

    useEffect(() => {
        const categorizedStagesCopy: { [categoryId: number]: Stage[] } = {};
        stageData.categories.forEach((category) => {
            categorizedStagesCopy[category.id] = stageData.stages.filter((stage) => stage.category === category.id);
        });
        setCategorizedStages(categorizedStagesCopy);
    }, [stageData]);

    useEffect(() => {
        console.log('categorizedStages', categorizedStages);
    }, [categorizedStages])

    const handleAddStage = (stage:Stage) => {
        const categorizedStagesCopy = { ...categorizedStages };
        categorizedStagesCopy[stage.category].push(stage);
        setCategorizedStages(categorizedStagesCopy);
    }

    useEffect(() => {
        const fetchResponses = async () => {
            if (!authTokens || !authTokens.access) {
                return;
            }
            try {
                const response = await fetch(`${ACTIVE_URL}/api/candidate_stages/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authTokens.access}`,
                    }
                });
                if (response.ok) {
                    const data = await response.json();

                    setStageData(data);
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        };

        fetchResponses();
    }, []);

    useEffect(() => {
        const fetchColorResponses = async () => {
            if (!authTokens || !authTokens.access) {
                return;
            }
            try {
                const response = await fetch(`${ACTIVE_URL}/api/get_all_status_colors/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authTokens.access}`,
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setStatusColors(data);
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        };

        fetchColorResponses();
    }, []);

    return (
        <div>
            <AdminBox>
                <AdimBoxTitle title='Status Management' />
                <div className='mx-4 border border-gray-400 rounded-md p-2'>
                    <div className='mb-4'>
                        <h2 className=''>
                            Pipeline
                        </h2>
                        <p className='text-sm text-gray-500'>Bellow the pipeline of the status prossesses is listed.</p>
                    </div>
                    {stageData.categories.map((category) => (
                        <div key={'category' + category.id} className='my-2'>
                            <div className='flex items-center gap-1'>
                                <h2 className='text-md' style={{ whiteSpace: 'nowrap' }}>{category.title}</h2>
                                <IonIcon icon="help-circle-outline" className='text-2xl cursor-pointer' />
                                <div className='w-full h-px bg-gray-300'></div>
                            </div>

                            {categorizedStages[category.id] && (
                                <ul className='list-none'>
                                    {categorizedStages[category.id].map((stage) => (
                                        <li key={stage.id} className='px-2 mx-0 my-3 py-2 border border-gray-400 rounded-sm flex items-center cursor-grab select-none w-full'>
                                            <div className='flex items-center gap-0'>
                                                <IonIcon icon='ellipsis-vertical-outline' />
                                                <IonIcon icon='ellipsis-vertical-outline' className='ml--2' />
                                            </div>
                                            <div className='ml-3'>
                                                {stage.title}
                                            </div>
                                            {stage.timelimit !== 0 && (
                                                <div className='flex items-center text-sm text-gray-600 gap-0.5 ml-3'>
                                                    <IonIcon icon="timer-outline" />
                                                    <div>
                                                        {stage.timelimit}
                                                        {stage.timelimit === 1 ? ' day' : ' days'}
                                                    </div>
                                                </div>
                                            )}
                                            <div className='ml-auto flex px-2 items-center text-gray-600 hover:text-black hover:bg-gray-200 cursor-pointer rounded-sm py-2' >
                                                <IonIcon icon='pencil-outline' />
                                            </div>
                                            <div className='mx-1 hover:bg-red-600 rounded-md px-2 py-2 cursor-pointer text-gray-600 hover:text-white flex items-center'>
                                                <IonIcon icon='trash-outline' />
                                            </div>
                                        </li>
                                    ))}
                                    <div>
                                        <AdminAddSettingsStatus onAddStage={handleAddStage} category_id={category.id} status_colors={statusColors}  />
                                    </div>
                                </ul>
                            )}
                        </div>
                    ))}
                </div>
            </AdminBox>
        </div>
    );

}

export default AdminSettingsStatusManagement;