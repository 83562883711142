import React, { useState } from 'react';
import { IonIcon } from '@ionic/react';


interface WhyUsSquareProps {
    className?: string;
    title: string;
    content_text:string;
    ion_icon: string;
}

const WhyUsSquare: React.FC<WhyUsSquareProps> = ({ title, content_text, ion_icon }) => {
    const [isHovered, setIsHovered] = useState(false);
    
    return (
        <div className={`bg-neutral-100 h-80 xmd:h-40 cursor-pointer hover:scale-105 duration-300 rounded-xl overflow-hidden relative`} onMouseOver={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <div className="p-2 xmd:p-3.5 h-full">
                <h3 className={`text-xl ${isHovered ? 'text-main' : 'text-neutral-800'}  mb-1 flex flex-col-reverse xmd:flex-row justify-between duration-200`}>
                    <div className='w-[85%] xmd:w-[90%] text-lg xmd:text-xl text-main'>{title}</div>
                    <div >
                        <IonIcon icon={ion_icon} className="text-3xl pt-0.5 pl-0.5 text-main" />
                    </div>
                </h3>
                <p className="text-neutral-500 w-[95%] text-[0.8rem]">{content_text}</p>
            </div>


        </div>
    )

}

export default WhyUsSquare;