import React, { useEffect } from 'react';
import DefaultLanguageInterface from '../../interfaces';
import PressHeader from '../../Components/Press/PressHeader/PressHeader';
import PressList from '../../Components/Press/PressList/PressList';
import DownloadOurApp from '../../Components/Home/DownloadOurApp/DownloadOurApp';

const PressPage: React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="flex flex-col">
            <header>
                <PressHeader activeLanguage={activeLanguage} />
            </header>
            <div className="flex-grow overflow-y-auto flex flex-col py-8">
                <PressList activeLanguage={activeLanguage} />
            </div>
            <DownloadOurApp activeLanguage={activeLanguage} selectedMode={'passenger'} />
        </div>
    );
};

export default PressPage;