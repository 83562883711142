import React from 'react';
import { Select, SelectTrigger, SelectContent, SelectGroup, SelectItem, SelectValue } from "../../../@/components/ui/select";

interface DriverFormCitySelectProps {
    selectedCity: string;
    setSelectedCity: (value: string) => void;
}

const DriverFormCitySelect: React.FC<DriverFormCitySelectProps> = ({ selectedCity, setSelectedCity }) => {
    return (
        <Select onValueChange={setSelectedCity} value={selectedCity} >
            <SelectTrigger className="outline-none h-12 text-neutral-600">
                <SelectValue placeholder="Επίλεξε Πόλη" />
            </SelectTrigger>
            <SelectContent className='w-full'>
                <SelectGroup>
                    <SelectItem key='1' value='Αθήνα' className='hover:text-main'>
                        <div className='hover:text-main'>
                            Αθήνα
                        </div>
                    </SelectItem>
                    <SelectItem key='2' value='Θεσσαλονίκη'>
                        <div className='hover:text-main'>
                            Θεσσαλονίκη
                        </div>
                    </SelectItem>
                    <SelectItem key='3' value='Πάτρα'>
                        <div className='hover:text-main'>
                            Πάτρα
                        </div>
                    </SelectItem>
                    <SelectItem key='4' value='Ιωάννινα'>
                        <div className='hover:text-main'>
                            Ιωάννινα
                        </div>
                    </SelectItem>
                    <SelectItem key='5' value='Κρήτη'>
                        <div className='hover:text-main'>
                            Κρήτη
                        </div>
                    </SelectItem>
                </SelectGroup>
            </SelectContent>
        </Select>
    );
};

export default DriverFormCitySelect;
