import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IonIcon } from "@ionic/react";
import SkeletonLoader from "../../../../utils/SkeletonLoader/SkeletonLoader";


interface HomeNewsItemProps {
    activeLanguage: string;
    grTitle: string;
    enTitle: string;
    grDescription: string;
    enDescription: string;
    image: string;
    slug: string;
    date: string;
    loading: boolean;
}


const HomeNewsItem: React.FC<HomeNewsItemProps> = ({ grTitle, enTitle, image, slug, date, loading, activeLanguage }) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const backgroundImageStyle = {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url('data:image/jpeg;base64,${image}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '12rem',
    };
    

    return (
        <Link to={'/news/' + slug} className="flex flex-col gap-2" onMouseOver={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            {loading ? <SkeletonLoader height={'208px'} width={'100%'} borderRadius={6} /> : <div style={backgroundImageStyle} className="rounded-md"></div>}
            <div className="flex flex-row gap-3">
                <div className="w-4/5">
                    {loading ? <SkeletonLoader height={'20px'} width={'100%'} borderRadius={6} /> : <h4 className="text-xl text-neutral-700 font-semibold">{activeLanguage === 'el' ? grTitle : enTitle}</h4>}

                    {loading ? <SkeletonLoader height={'10px'} width={'30%'} borderRadius={6} /> : <p className="text-xs text-neutral-600">{date ? new Date(date).toLocaleDateString('en-GB') : ''}</p>}
                </div>
                <div className="w-1/5 flex items-center justify-center">
                {loading ? <SkeletonLoader isCircle={true} height={'35px'} width={'35px'} /> : <IonIcon icon='chevron-forward-outline' className={`${isHovered ? 'ml-3 text-main' : 'text-neutral-600'} text-xl duration-200`} />}
                </div>
            </div>
        </Link>
    )
}

export default HomeNewsItem;